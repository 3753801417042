import LayoutDefault from "@/views/layouts/Default";

import MainLogin from "@/views/pages/MainLogin";
import TenantLogin from "@/views/pages/TenantLogin";
import SuccessPage from "@/views/pages/Success";
import SurveyPage from "@/views/pages/Survey";
import SurveyAd from "@/views/pages/Ad";
import Suspended from "@/views/pages/Suspended";
import FortiLogin from "@/views/pages/FortiLogin";
import GwLogin from "@/views/pages/GwLogin";

export default {
  path: "/",
  component: LayoutDefault,
  children: [
    {
      path: "/",
      name: "main-login",
      component: MainLogin,
    },
    {
      path: "/gw_id/:gw_id",
      name: "gw-login",
      component: GwLogin,
    },
    {
      path: "/tenant/:tenantId",
      name: "tenant-login",
      component: TenantLogin,
    },
    {
      path: "/auth/wifidogAuth/auth",
      name: "wifidogAuth-login",
      beforeEnter: () => {
        window.location.href =
          "http://apis.obifi.io/auth/wifidogAuth/auth";
      },
    },
    {
      path: "/forti/:gatewaymac",
      name: "forti-login",
      component: FortiLogin,
    },
    {
      path: "/preview/:portalHid",
      name: "preview-portal",
      component: MainLogin,
    },
    {
      path: "/success",
      name: "success-page",
      component: SuccessPage,
    },
    {
      path: "/survey/:surveyId/:rHid",
      name: "survey-page",
      component: SurveyPage,
    },
    {
      path: "/ads/:adId/:rHid",
      name: "ads-page",
      component: SurveyAd,
    },
    {
      path: "/suspended",
      name: "suspended-page",
      component: Suspended,
    },
  ],
};
